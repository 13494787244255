import React, { useState } from 'react';
import { Link } from 'gatsby';
import Navbar from '../lib/components/Navbar/Navbar';
import Grid from '../lib/components/Grid/Grid';
import CenterContainer from '../lib/components/CenterContainer/CenterContainer';
import GridSkeletons from '../lib/components/GridSkeletons/GridSkeletons';
import CardVertical from '../lib/components/CardVertical/CardVertical';
import Breadcrumbs from '../lib/components/Breadcrumbs/Breadcrumbs';
import PageHeader from '../lib/components/PageHeader/PageHeader';
import Button from '../lib/components/Button/Button';
import stringifyTimestamp from '../utils/stringifyTimestamp';
import { Helmet } from 'react-helmet';
import GridDraggable from '../lib/components/GridDraggable';

const Deck = ({ data, userGlobalState, onDragEnd }) => {
  const deck = data && data.deck;
  const deckItems = deck && deck.deckItems;

  return (
    <div>
      <Helmet>
        <title>My Deck</title>
      </Helmet>
      <Navbar />
      <CenterContainer>
        <PageHeader>
          {deck ? (
            <>
              <div>
                <Breadcrumbs
                  crumbsArr={[
                    {
                      title: 'My Decks',
                      to: `/dashboard/myDecks?artistId=${deck.artist}`
                    },
                    { title:'Deck' }
                  ]}
                />
                <h1 className="breadCrumbTitle">{deck.title}</h1>
                <div
                  style={{
                    fontSize: '13px',
                    marginTop: '8px',
                    color: '#c4c4c4'
                  }}
                >
                  <span>
                    {stringifyTimestamp({ timestamp: deck.createdAt })}
                  </span>
                  <span style={{ margin: '0px 5px' }}>|</span>
                  <span>{(deck.deckItems || []).length} Items</span>
                </div>
              </div>
              <Link
                to={`/dashboard/items/create?deckId=${deck._id}&deck=${deck.title}`}
              >
                <Button>Upload Item</Button>
              </Link>
            </>
          ) : null}
        </PageHeader>
        {/* <Grid>
          {deckItems && userGlobalState && userGlobalState.artist ? (
            deckItems.map((item) => {
              return (
                <CardVertical
                  key={item._id}
                  cardLink={`/deckItem?_id=${item._id}&deckName=${deck.title}&deckId=${deck._id}&by=${userGlobalState.artist.name}`}
                  title={item.title}
                  imgUrl={item.imgUrl}
                />
              );
            })
          ) : (
            <GridSkeletons />
          )}
        </Grid> */}
        {deckItems && userGlobalState && userGlobalState.artist ? (
          <GridDraggable
            onDragEnd={onDragEnd}
            items={deckItems}
            deck={deck}
            userGlobalState={userGlobalState}
          />
        ) : (
          <GridSkeletons />
        )}
      </CenterContainer>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Deck;

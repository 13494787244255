
import { api_base_url } from '../../../../global_data';
import axios from "axios";


const fetch_data = async ({ setState, deckId }) => {
    
    const res = await axios({
        url: `${api_base_url}/decks/${deckId}?populate=true`,
        method: 'GET'
    });

    const data = res.data;
    setState({deck: data});

}

export default fetch_data;
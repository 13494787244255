
import React from "react";
import DeckPageContainer from '../Deck/containers/DeckPageContainer';


const DeckPage = ({ location }) => {

  return (
    <DeckPageContainer location={location}/>
  )
}

export default DeckPage;

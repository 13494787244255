import React from 'react';
import styles from './GridDraggable.module.css';
import SortableList, { SortableItem } from 'react-easy-sort';
import CardVertical from '../CardVertical/CardVertical';

const GridDraggable = ({ items, deck, userGlobalState, onDragEnd }) => {
  return (
    <SortableList
      onSortEnd={onDragEnd}
      className={styles.Grid}
      draggedItemClassName="dragged"
    >
      {(items || []).map((item, i) => (
        <SortableItem key={item._id}>
          <div className="item">
            <CardVertical
              cardLink={`/deckItem?_id=${item._id}&deckName=${deck.title}&deckId=${deck._id}&by=${userGlobalState.artist.name}`}
              title={item.title}
              imgUrl={item.imgUrl}
              noDrag={true}
              position={i}
            />
          </div>
        </SortableItem>
      ))}
    </SortableList>
  );
};

export default GridDraggable;

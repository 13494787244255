import React, { useEffect, useContext } from 'react';
import useSetState from '../../utils/useSetState';
import fetch_data from './utils/fetch_data';
import Deck from '../Deck';
import queryString from 'query-string';
import { UserContext } from '../../Context/UserContext/UserContext';
import { FeedbackContext } from '../../Context/FeedbackContext/FeedbackContext';
import arrayMove from 'array-move';
import { cloneDeep } from 'lodash';
import saveNewOrderOnDb from '../../utils/saveNewOrderOnDb';

const DeckPageContainer = ({ location }) => {
  const [state, setState] = useSetState({});
  const { userGlobalState } = useContext(UserContext);
  const { setFeedbackState } = useContext(FeedbackContext);

  useEffect(() => {
    const queryObj = queryString.parse(location.search);
    const deckId = queryObj['_id'];
    fetch_data({ setState, deckId });
  }, []);

  const onDragEnd = (oldIndex, newIndex) => {
    const arrayClone = cloneDeep(state.deck.deckItems);

    const newArray = arrayMove(arrayClone, oldIndex, newIndex);

    setState({
      deck: { ...state.deck, deckItems: newArray }
    });

    saveNewOrderOnDb({
      collection: 'deckItem',
      _id: state.deck._id,
      newArray: newArray,
      setFeedbackState
    })
  };

  return (
    <Deck
      data={state}
      userGlobalState={userGlobalState}
      onDragEnd={onDragEnd}
    />
  );
};

export default DeckPageContainer;

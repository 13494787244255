import { api_base_url } from '../../global_data';
import axios from 'axios';

const saveNewOrderOnDb = async ({ collection, newArray, _id, setFeedbackState }) => {
  try {
    setFeedbackState({
      isActive: true,
      isLoading: true
    });

    const newArrayOfIds = newArray.map((ele) => {
      return ele._id;
    });

    await axios({
      url: `${api_base_url}/decks/updateOrder`,
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json; charset=utf-8'
      },
      data: JSON.stringify({
        deckId: _id,
        newArrayOfIds
      })
    });

    setFeedbackState({
      isActive: true,
      isError: false,
      isLoading: false,
      message: 'Saved new order'
    });
  } catch (err) {
    console.log('err', err);
    setFeedbackState({
      isActive: true,
      isError: true,
      isLoading: false,
      message: 'Error on saving new order'
    });
    // just logg error
  }
};

export default saveNewOrderOnDb;
